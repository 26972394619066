/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { Directions } from 'SourceComponent/ChevronIcon/ChevronIcon.config';
import { ChevronIconComponentProps } from 'SourceComponent/ChevronIcon/ChevronIcon.type';
import { ReactElement } from 'Type/Common.type';

import './ChevronIcon.style';

/** @namespace TrouperPwa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends PureComponent<ChevronIconComponentProps> {
    static defaultProps: Partial<ChevronIconComponentProps> = {
        direction: Directions.RIGHT,
    };

    render(): ReactElement {
        const { direction } = this.props;
        /* eslint-disable */
        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6955 0.8619C4.43563 1.12178 4.43563 1.54312 4.6955 1.803L10.8928 8.00033L4.6955 14.1977C4.43563 14.4575 4.43563 14.8789 4.6955 15.1388C4.95538 15.3986 5.37673 15.3986 5.6366 15.1388L12.3045 8.47087C12.4293 8.34608 12.4994 8.17682 12.4994 8.00033C12.4994 7.82384 12.4293 7.65457 12.3045 7.52978L5.6366 0.8619C5.37673 0.602023 4.95538 0.602023 4.6955 0.8619Z" fill="#04132D"/>
            </svg>
        );
    }
}

export default ChevronIconComponent;
